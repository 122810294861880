import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, Container } from "react-bootstrap"
import sectionImage from "../images/sectionimage.png"
import { Helmet } from "react-helmet";
import '../css/carousel.css'

function HomePageCarousel() {

  return (
    <Carousel
      slide={false}
      interval={null}
      controls={false}
    indicators={false}
    >
      <Carousel.Item >
        <img
          className="d-block w-100"
          src={sectionImage}
          alt="First slide"
        />
        <Carousel.Caption>
          <Helmet>
            <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
          </Helmet>
            <div class="col-lg-6 mx-auto">
              <div className="d-grid d-sm-flex justify-content-center">
                <ul className="list-unstyled d-flex">
                  <a href="https://www.youtube.com/ThisIsYolid" target="_blank"><li><span className="fa-stack fa-3x" style={{ color: "black" }}>
                    <i className="far fa-circle fa-stack-2x"></i>
                    <i className="fab fa-instagram fa-stack-1x"></i>
                  </span></li></a>
                  <a href="https://www.youtube.com/ThisIsYolid" target="_blank"><li><span className="fa-stack fa-3x" style={{ color: "black" }}>
                    <i className="far fa-circle fa-stack-2x"></i>
                    <i className="fab fa-twitter fa-stack-1x"></i>
                  </span></li></a>
                  <a href="https://www.youtube.com/ThisIsYolid" target="_blank"><li><span className="fa-stack fa-3x" style={{ color: "black" }}>
                    <i className="far fa-circle fa-stack-2x"></i>
                    <i className="fab fa-youtube fa-stack-1x"></i>
                  </span></li></a>
                </ul>
              </div>
            </div>
        </Carousel.Caption>
      </Carousel.Item>

    </Carousel>
  );
}

export default HomePageCarousel;