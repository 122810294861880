import React from "react"
import { Link, graphql } from "gatsby" //highlight-line
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Row, Col, Image, Nav, Navbar } from "react-bootstrap"
import styled from "styled-components";
import { Helmet } from "react-helmet";
import sectionimage from "../images/sectionimage.png"
import NavImageLogo from "../images/logo.png"
import HomePageCarousel from "../components/HomePageCarousel";
import '../css/normalize.css'
import '../css/index.css'

const Section1 = styled.section`
.noBottomPadding {
    padding-bottom: 0px;
}
`;

export default function Home({ data }) {
  return (
    <div>
      <Helmet>
        <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
      </Helmet>
      <SEO title="home" />

      <Navbar collapseOnSelect className="py-4 shadow rounded" bg="light" variant="light" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand>
            <img
              src={NavImageLogo}
              width="64"
              height="64"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href=""><h3>Home</h3></Nav.Link>
              <Nav.Link href="talent"><h3>Talent</h3></Nav.Link>
              <Nav.Link href="project"><h3>Project</h3></Nav.Link>
              <Nav.Link href="about"><h3>About</h3></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="py-2">
        <HomePageCarousel />
      </div>

      <Section1>
        <br></br>
        <Container>
          <Row xs={1} md={2} lg={2}>
            {data.allWpPost.nodes.map(node => (
              <Col md={6}>
                <div className="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3 shadow-sm">
                  <div className="my-3 py-3">
                    <Image variant="top" src={node.featuredImage?.node?.sourceUrl} className="Section1Image" width="95%" height="300px" alt={node.featuredImage?.node?.altText} fluid />
                    {/* <strong className="d-inline-block mb-2 text-primary">
                      {" "}
                      {node.categories.nodes.map((category, index) => [
                        index > 0 && ", ",
                        <Link key={index}>
                          {category.name}
                        </Link>,
                      ])}
                    </strong> */}
                    <div class="divider my-1 py-1"></div>
                    <h2 className="display-5 SerifFont"><b>{node.title}</b></h2>
                    <p className="lead">
                      {" "}
                      {node.tags.nodes.map((tag, index) => [
                        index > 0 && ", ",
                        <Link key={index}>
                          {tag.name}
                        </Link>,
                      ])}
                    </p>
                    <Link to={node.slug}><Button variant="dark">Read More</Button></Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section1>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: {fields: [date], order: DESC}, limit: 4) {
      nodes {
        title
        excerpt
        slug
        uri
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            sourceUrl
            altText
          }
        }
        author {
          node {
            lastName
            firstName
            id
            avatar {
              url
            }
          }
        }
        tags {
          nodes {
            name
            link
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
    }
  }
`